exports.components = {
  "component---src-sites-type-auto-eurohotelik-templates-article-tsx": () => import("./../../../src/sites/typeAuto/eurohotelik/templates/Article.tsx" /* webpackChunkName: "component---src-sites-type-auto-eurohotelik-templates-article-tsx" */),
  "component---src-sites-type-auto-eurohotelik-templates-articles-tsx": () => import("./../../../src/sites/typeAuto/eurohotelik/templates/Articles.tsx" /* webpackChunkName: "component---src-sites-type-auto-eurohotelik-templates-articles-tsx" */),
  "component---src-sites-type-auto-eurohotelik-templates-companies-tsx": () => import("./../../../src/sites/typeAuto/eurohotelik/templates/Companies.tsx" /* webpackChunkName: "component---src-sites-type-auto-eurohotelik-templates-companies-tsx" */),
  "component---src-sites-type-auto-eurohotelik-templates-company-tsx": () => import("./../../../src/sites/typeAuto/eurohotelik/templates/Company.tsx" /* webpackChunkName: "component---src-sites-type-auto-eurohotelik-templates-company-tsx" */),
  "component---src-sites-type-auto-eurohotelik-templates-contact-tsx": () => import("./../../../src/sites/typeAuto/eurohotelik/templates/Contact.tsx" /* webpackChunkName: "component---src-sites-type-auto-eurohotelik-templates-contact-tsx" */),
  "component---src-sites-type-auto-eurohotelik-templates-cookies-policy-tsx": () => import("./../../../src/sites/typeAuto/eurohotelik/templates/CookiesPolicy.tsx" /* webpackChunkName: "component---src-sites-type-auto-eurohotelik-templates-cookies-policy-tsx" */),
  "component---src-sites-type-auto-eurohotelik-templates-cookies-table-tsx": () => import("./../../../src/sites/typeAuto/eurohotelik/templates/CookiesTable.tsx" /* webpackChunkName: "component---src-sites-type-auto-eurohotelik-templates-cookies-table-tsx" */),
  "component---src-sites-type-auto-eurohotelik-templates-home-tsx": () => import("./../../../src/sites/typeAuto/eurohotelik/templates/Home.tsx" /* webpackChunkName: "component---src-sites-type-auto-eurohotelik-templates-home-tsx" */),
  "component---src-sites-type-auto-eurohotelik-templates-not-found-tsx": () => import("./../../../src/sites/typeAuto/eurohotelik/templates/NotFound.tsx" /* webpackChunkName: "component---src-sites-type-auto-eurohotelik-templates-not-found-tsx" */),
  "component---src-sites-type-auto-eurohotelik-templates-search-tsx": () => import("./../../../src/sites/typeAuto/eurohotelik/templates/Search.tsx" /* webpackChunkName: "component---src-sites-type-auto-eurohotelik-templates-search-tsx" */),
  "component---src-sites-type-auto-eurohotelik-templates-terms-tsx": () => import("./../../../src/sites/typeAuto/eurohotelik/templates/Terms.tsx" /* webpackChunkName: "component---src-sites-type-auto-eurohotelik-templates-terms-tsx" */)
}

